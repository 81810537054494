import { format, type FormatOptions } from 'date-fns';

export const FORMATS = {
  apiFormat: 'yyyy-MM-dd',
  confirmationFormat: 'EEE, do LLL yyyy',
  confirmationFormatAlt: 'EEEE d LLLL yyyy',
  dateMonthYear: 'd LLLL yyyy',
  datePickerFormat: 'd LLL',
  inputFormat: 'dd LLL',
  paymentFormat: 'do LLL yyyy',
  readableFormat: 'd LLL yy',
  slashedFormat: 'dd/MM/yyyy',
};

export const formatDate = (
  date: Date,
  formatting: string,
  options?: FormatOptions,
) => {
  if (!date) return '';

  return format(date, formatting, options);
};

/**
 * Extracts a date from an API string (yyyy-MM-dd) to local Date object with the correct timezone
 */
export const extractDateFromApiString = (date?: string | null): Date => {
  if (typeof date !== 'string') {
    throw new TypeError('Invalid date format');
  }

  const dateString = date.slice(0, 10);
  const validDate = Date.parse(dateString);

  if (Number.isNaN(validDate)) {
    throw new TypeError('Invalid date format');
  }

  const [year, month, day] = date.slice(0, 10).split('-');

  return new Date(Number(year), Number(month) - 1, Number(day));
};
